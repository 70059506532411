<template>
  <div class="view view--tools view--tools-videoblog">
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-7">
            <div class="wrapper wrapper--v-center">
              <p class="txt fw-400 fc-green ts-20 ts-md-22 mb-3" v-html="cc.s1.tit[0]" />
              <h1 class="tit fw-500 fc-dark ts-36 ts-md-48 mb-4" v-html="cc.s1.tit[1]" />
              <p class="txt fw-400 fc-gray mb-4" v-html="cc.s1.txt" />
            </div>
          </div>
          <div class="col-12 col-lg-5">
            <div class="img img--tools-tutorials">
              <img src="/img/views/img-tools-tutorials.svg" alt="tutorials" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--dark">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <comp-videonews />
            <div :class="{ 'd-none': $i18n.locale === 'cs'}">
              <h2 class="tit fw-500 fc-green ts-36 ts-md-48 ta-center">Comming Soon ...</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <comp-modal comp="m1" :data="videoKey"/>
  </div>
</template>
<script>
import CompVideonews from '@/components/Videonews'
import CompModal from '@/components/Modal'
export default {
  data: () => ({
    videoKey: 'G0xPI99Fliw',
    videoKeys: {
      cs: [
        {
          key: '1cNdTuP49qw',
          title: 'Coinfy minutes - opce, dluhopisy, akcie a vliv na #btc',
          date: '30.12.2022'
        },
        {
          key: 'Laqgq7UQh8E',
          title: 'Coinfy minutes - stabilizace BTC a blížící se konec medvědího trendu',
          date: '23.12.2022'
        },
        {
          key: 'lAbQmh1MUto',
          title: 'Coinfy minutes - listopadová inflace, úrokové sazby a vliv na BTC',
          date: '16.12.2022'
        },
        {
          key: 'G0xPI99Fliw',
          title: 'Zadlužování a vliv na inflaci v USA',
          date: '06.12.2022'
        },
        {
          key: '2TY8nA_U9EM',
          title: 'Expirace opcí BTC, Bitcoin All Time high',
          date: '02.12.2022'
        },
        {
          key: 'q6temlfWubc',
          title: 'Obtížnost těžby, akcie ETF, denní graf',
          date: '30.11.2022'
        },
        {
          key: 'pvr1Vfnwjcg',
          title: 'FED, halving BTC, denní graf',
          date: '29.11.2022'
        }
      ]
    }
  }),
  components: {
    CompVideonews, CompModal
  },
  computed: {
    cc () {
      return this.$t('views.tools.videonews')
    }
  },
  methods: {
    toggleVideo (i) {
      const keys = this.videoKeys[this.$i18n.locale]
      this.videoKey = keys[i].key
      this.$store.state.isModalActive = true
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
